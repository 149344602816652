import React from 'react'
import Helmet from 'react-helmet'

export const GlobalHelmet = ({ title = 'UKE jukebox' }) => (
  <Helmet>
    <html lang="de" />
    <meta charset="UTF-8" />
    <meta name="theme-color" content="#004992" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>{title}</title>
    <meta name="title" content="UKE jukebox - Start" />
    <meta
      name="description"
      content="Du bist auf der Suche nach deinem Match? Spiele unsere jukebox und finde noch heute dein Pflege-Dream-Team im UKE."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://jukebox.uke.de/" />
    <meta property="og:title" content="UKE jukebox - Start" />
    <meta
      property="og:description"
      content="Du bist auf der Suche nach deinem Match? Spiele unsere jukebox und finde noch heute dein Pflege-Dream-Team im UKE."
    />
    <meta property="og:image" content="/img/meta-content-image.png" />
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://jukebox.uke.de/" />
    <meta property="twitter:title" content="UKE jukebox - Start" />
    <meta
      property="twitter:description"
      content="Du bist auf der Suche nach deinem Match? Spiele unsere jukebox und finde noch heute dein Pflege-Dream-Team im UKE."
    />
    <meta property="twitter:image" content="/img/meta-content-image.png" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/img/favicons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/img/favicons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/img/favicons/favicon-16x16.png"
    />
    <link rel="manifest" href="/img/favicons/site.webmanifest" />
    <link
      rel="mask-icon"
      href="/img/favicons/safari-pinned-tab.svg"
      color="#004992"
    />
    <link rel="shortcut icon" href="/img/favicons/favicon.ico" />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta
      name="msapplication-config"
      content="/img/favicons/browserconfig.xml"
    />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
)
